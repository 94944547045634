// Dependencies
import Vue from 'vue'

// Component Extend
const Spinner = Vue.extend({
	name: 'Spinner',

	props: {
		border: { type: String, default: '.35rem' },
		show: { type: Boolean, default: true },
		size: { type: String, default: '3rem' },
		variant: { type: String, default: 'success' }
	},

	methods: {
		_getStyle: function() {
			const { border, size } = this.$props
			return `
				border-width: ${ border };
				height: ${ size };
				width: ${ size };
			`
		}
	}
})

// Exports
export default Spinner
export type SpinnerRef = InstanceType<typeof Spinner>