// Dependencies
import Vue from 'vue'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const ModuleBox = Vue.extend({
	name: 'ModuleBox',

	props: {
		bottomColor: String,
		description: String,
		icon: String,
		path: String,
		title: String,
		topColor: String
	},

	data: function() {
		return {
			isHovered: false
		}
	},

	methods: {
		onRedirectToSystem: function(systemName: string) {
			const { viewMode } = Store.getters.getStoredViews
			this.$router.push({ name: systemName, params: { viewMode } })
		}
	}
})

// Exports
export default ModuleBox
export type ModuleBoxRef = InstanceType<typeof ModuleBox>