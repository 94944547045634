import { render, staticRenderFns } from "./template.vue?vue&type=template&id=1636c883&scoped=true&"
import script from "@/Views/Modules/1/10/component.ts?vue&type=script&lang=ts&"
export * from "@/Views/Modules/1/10/component.ts?vue&type=script&lang=ts&"
import style0 from "@/Views/Modules/1/10/style.scss?vue&type=style&index=0&id=1636c883&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1636c883",
  null
  
)

export default component.exports